<script setup>
import {defineProps} from 'vue'
const props = defineProps(["data"])

function hashName(t) {
  if (t == 100) return "JA3S"
  if (t == 101) return "JA3 Inbound"
  if (t == 200) return "JA4"
  if (t == 201) return "JA4SSH"
  if (t == 202) return "JA4T"
  if (t == 203) return "JA4H"
  if (t == 204) return "JA4 Inbound"
  if (t == 205) return "JA4H Inbound"
  if (t == 206) return "JA4LS"
  if (t == 207) return "JA4S"
  if (t == 208) return "JA4SSH Inbound"
  if (t == 209) return "JA4TS"
  if (t == 500) return "Jarm"

  return t
}

</script>

<template>
  <div>
    <span class="" v-if="props.data != null">{{ hashName(props.data) }}</span>
  </div>
</template>

<style scoped>

</style>

