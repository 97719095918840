<script setup>
import { onMounted, ref, onUnmounted } from 'vue'
import Ip from '@/api/Ip.js'

import CellExpandedSlideover from '@/components/Query/CellDataSlideover.vue'

import { useMainStore } from '@/stores/main'
let mainStore = useMainStore()
  
import CalHeatmap from 'cal-heatmap';
import Legend from 'cal-heatmap/plugins/Legend';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
import LegendLite from 'cal-heatmap/plugins/LegendLite';

// Optionally import the CSS
import 'cal-heatmap/cal-heatmap.css';
let cal; 

//"ip": "205.210.31.219",
//"end": 1738247634,
//"start": 1735686000
const authorized = ref(true)

const props = defineProps({
  ip: { type: String },
  start: { 
    type: Number,
    default: Math.floor(new Date().setMonth(new Date().getMonth() - 11))
  },
  end: { 
    type: Number,
    default: Math.floor(Date.now()),
  },
});

async function handle_cell_click(e, timestamp_ns, value) {
  if (!value) return 

  let start = Math.floor(timestamp_ns/1000)
  let end = start + (60*60*24)
  let events = (await Ip.EventRange(props.ip, start, end))

  mainStore.show_slideover(CellExpandedSlideover, { data: { 'title': "Events for " + props.ip, 'data': events }})
}

async function list_all() {

  let start = Math.floor(props.start/1000)
  let end = Math.floor(props.end/1000)
  let events = (await Ip.EventRange(props.ip, start, end))

  mainStore.show_slideover(CellExpandedSlideover, { data: { 'title': "Events for " + props.ip, 'data': events }})
}

onUnmounted( async () => {
  await cal.destroy()
})

onMounted(async () => {
  let events = []
  try {  
    events = (await Ip.Events(props.ip, Math.floor(props.start / 1000), Math.floor(props.end / 1000))).data
  } catch (Exception) {
    authorized.value = false
  }

  cal = new CalHeatmap();
  
  cal.on('click', handle_cell_click)

  cal.on('mouseover', (e, timestamp, value) => {
    if (value) {
      e.target.style.cursor = "pointer";
    }
  })

  cal.on('mouseout', (e, timestamp, value) => {
    e.target.style.cursor = "default";
  })

  cal.paint(
    {
      data: {
        source: events,
        x: "date", 
        y: "num_events"
      },
      date: { 
        start: new Date(props.start),
        min: props.start,
        max: props.end
      },
      range: 12,
      scale: {
        color: {
          type: 'threshold',
          range: ['#14432a', '#166b34', '#37a446', '#4dd05a'],
          domain: [1, 5, 10, 30],
        },
      },
      domain: {
        type: 'month',
        gutter: 4,
        label: { text: 'MMM', textAlign: 'start', position: 'top' },
      },
      subDomain: { type: 'ghDay', radius: 2, width: 11, height: 11, gutter: 4 },
      itemSelector: document.querySelector("#cal-heatmap"),
      theme: 'dark'
    },
     [
        [
          Tooltip,
          {
            text: function (date, value, dayjsDate) {
              return (
                (value ? value : 'No') +
                ' events on ' +
                dayjsDate.format('dddd, MMMM D, YYYY')
              );
            },
          },
        ],
        [
          LegendLite,
          {
            includeBlank: true,
            itemSelector: '#ex-ghDay-legend',
            radius: 2,
            width: 11,
            height: 11,
            gutter: 4,
          },
        ],
        [
          CalendarLabel,
          {
            width: 30,
            textAlign: 'start',
            text: () => dayjs.weekdaysShort().map((d, i) => (i % 2 == 0 ? '' : d)),
            padding: [25, 0, 0, 0],
          },
        ],
      ]
    );

    })

</script>


<template>
  <div>
     <div id="cal-heatmap"></div>   
     <div class="d-flex pt-2">
      <small class="ms-auto" @click="list_all()">list all</small>
     </div>
     <div v-if="!authorized" class="unauthorized">
       <span>To view event information upgrade to the &nbsp;<router-link to="/plans">Elite or Enterprise Plan</router-link></span>
     </div>
  </div>
</template>

<style scoped>
small:hover {
  cursor: pointer;
  color: white;
}  

.unauthorized {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  color: white;
  bottom: 0;
  background-color: #1a1f27ab;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
