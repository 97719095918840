<script setup>
  import { defineProps, nextTick, ref, onMounted, computed } from 'vue'
  import EventGraph from '@/components/EventGraph.vue'
  import Table from '@/components/Query/Table'
  import Domain from '@/api/Domain'
  import Ip from '@/api/Ip'
  import { Ports } from '@/lib/port_desc.js'

  const domains = ref(null)
  const hashes = ref(null)
  const renderDomains = ref(false)
  const renderHashes = ref(false)
  const eventAuthorized = ref(true)
  
  let ports = [];  

  // Three line description.
  // 1st: The ip + geo
  // 2nd: The danger level (if any) - else domains
  // 3rd: The owner / operator information
  const sentence = computed(() => {
    // 1st: The ip + geo
    let first = "" 
    // add conditionals for country, city, and hostname
    first = "The IP address " + props.data.ip + " is located in " + props.data.location.country_name + ""

    if (props.data.hostnames) first += ", and announces itself as " + props.data.hostnames[0] + ". " 
    else first += ". "

    // 2nd: The danger level (if any) - else domains
    let second = ""
    if (malicious.value || suspicious.value) {
      second = "We have observed it engaging in behavior commonly associated with criminal activity or its precursors. We strongly advise exercising caution when interacting with it. "  
    } else if (anonymization.value) {
      second = "We have observed this system operate as anonymization infrastructure. We advise exercising caution, implementing stricter access controls, and monitoring for suspicious activity. "
    } else {
      // talk about hosted domains...
    }

    // 3rd: The owner / operator information
    let third = ""
    if (props.data.operator) {
      third = "The infrastructure has been tied directly " + props.data.operator  + "'s operations"
      if (props.data.network_provider) {
        third += ", who has likely purchased access to it through " + props.data.network_provider + ". "
      } else {
        third += ". "
      }
    } else if (props.data.network_provider) {
      third = "This system is associated with "+ props.data.network_provider+", and is likely used by a third party"
    }

    return first + second + third 
  })

  const props = defineProps({
    data: {
      type: Object,
      default: {}
    } 
  });
  
  if (props.data.open_ports) {
    props.data.open_ports.forEach(port => {
      let elem = Ports.find(elem => elem.port == port)
      if (elem) ports.push(elem)
    })
  }

  function getLabel(tag) {
    if (tag.includes("bruteforce") || tag.includes("malicious") || tag.includes("crime") || tag.includes("c2")) return "bad"
    else if (tag.includes("tor") || tag.includes("vpn") || tag.includes("anonymization") || tag.includes("proxy") || tag.includes("probing") || tag.includes("scanning")) return "warning"
  }

  const malicious = computed(() => {
    if (!props.data.behavior) { return false }
    return props.data.behavior.includes("bruteforce") ||
          props.data.behavior.includes("malicious") ||
          props.data.behavior.includes("crime") ||
          props.data.behavior.includes("c2")
  })

  const anonymization = computed(() => {
    if (!props.data.behavior) { return false }
    return props.data.behavior.includes("tor") ||
          props.data.behavior.includes("vpn") ||
          props.data.behavior.includes("anonymization") || 
          props.data.behavior.includes("proxy") 
  })

  const suspicious = computed(() => {
    if (!props.data.behavior) { return false }
    return (props.data.behavior.includes("scanning") ||
          props.data.behavior.includes("probing")) && !malicious.value
  })

  onMounted(async () => {
    domains.value = (await Domain.reverse_dns(props.data.ip)).data

    try {
      hashes.value = (await Ip.Hashes(props.data.ip)).data
    } catch (Exception) {
      eventAuthorized.value = false
      hashes.value = [
        {
            "hash_type": 209,
            "hash": "**********************",
            "last_seen": "2024-11-14T04:16:48Z",
            "port": 443
        },
        {
            "hash_type": 202,
            "hash": "**********************",
            "last_seen": "2024-10-17T00:18:11Z",
            "port": null
        },
        {
            "hash_type": 206,
            "hash": "*********",
            "last_seen": "2024-11-14T04:16:52Z",
            "port": 80
        },
        {
            "hash_type": 206,
            "hash": "**************",
            "last_seen": "2024-11-14T04:16:48Z",
            "port": 443
        },
        {
            "hash_type": 101,
            "hash": "********************************",
            "last_seen": "2023-11-12T02:44:18Z",
            "port": null
        },
        {
            "hash_type": 101,
            "hash": "********************************",
            "last_seen": "2024-04-06T13:46:12Z",
            "port": null
        },
        {
            "hash_type": 201,
            "hash": "******************",
            "last_seen": "2024-11-01T11:25:12Z",
            "port": null
        },
        {
            "hash_type": 201,
            "hash": "*****************",
            "last_seen": "2024-10-17T00:18:11Z",
            "port": null
        },
        {
            "hash_type": 201,
            "hash": "*****************",
            "last_seen": "2024-10-27T00:02:57Z",
            "port": null
        }
    ]
    }

    props.data.domains_on_ip = domains.value.length

    renderHashes.value = true;
    renderDomains.value = true;
  })
  
</script>

<template>
  <div style="overflow: auto;">
  <div class="container pt-5" >
    <div class="labels mb-2">
      <div v-if="malicious" class="label bad me-2">malicious</div>
      <div v-if="suspicious" class="label warning me-2">suspicious</div>
      <div v-if="anonymization" class="label warning">anonymization</div>
    </div>

    <div class="row mb-4">
      <div class="col-md-3" style="height: 72px;">
        <h2>{{ props.data.ip}}</h2>
        <div class="d-flex">
          <img height="16" v-if="props.data.location.country_iso != ''" class="me-2" style="border-radius: 5px;" :src="require('@/assets/flags/4x3/' + props.data.location.country_iso.toLowerCase() + '.svg')">
          <div><span v-if="props.data.location.city">{{ props.data.location.city}}, </span> {{ props.data.location.country_name}}, {{props.data.location.region}}</div>
        </div>
      </div>

      <div class="col-md-9" style="height: 72px; overflow: auto; display: flex; align-items: center;">
        <div style="font-size: .9rem;">
          {{ sentence }}
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-8">
        <div class="box padded h100">
          <strong class="white d-flex mb-2">
            Summary
          </strong>
          <div class="kv">
            <div class="item">
              <div>Risk Score</div>
              <div>{{ props.data.risk_score}}</div>
            </div>
            <div class="item">
              <div>AS Number</div>
              <div>
                <span class="ms-2" v-for="as in props.data.asn" :key="as">
                AS{{ as }}
                </span>
              </div>
            </div>
            <div class="item">
              <div>AS Organization</div>
              <div>{{ props.data.as_org}}</div>
            </div>
            <div class="item">
              <div>Hostname</div>
              <div>
                <span class="ms-2" v-for="hostname in props.data.hostnames" :key="hostname">
                {{ hostname }}
                </span>
              </div>
            </div>
            <div class="item">
              <div>Company</div>
              <div>{{ props.data.network_provider}}</div>
            </div>
            <div class="item">
              <div>Hosted domains</div>
              <div>{{ props.data.domains_on_ip}}</div>
            </div>
            <div class="item" v-if="props.data.operator">
              <div>Operator</div>
              <div>{{ props.data.operator}}</div>
            </div>
            <div class="item" v-if="props.data.operator_description">
              <div>Operator Description</div>
              <div>{{ props.data.operator_description}}</div>
            </div>
            <div class="item">
              <div>Network Type</div>
              <div>{{ props.data.network_type}}</div>
            </div>
            <div class="item">
              <div>Network Range</div>
              <div>{{ props.data.network_range}}</div>
            </div>
            <div class="item" v-if="props.data.network_service">
              <div>Network Service</div>
              <div>{{ props.data.network_service}}</div>
            </div>
            <div class="item"  v-if="props.data.network_service_region">
              <div>Service Region</div>
              <div>{{ props.data.network_service_region}}</div>
            </div>
            <div class="item" >
              <div>Private Network</div>
              <div>{{ props.data.is_private}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="box padded h100">
          <strong class="white d-flex mb-2">
          behavior & risk 
          </strong>
          <div class="d-flex" v-if="props.data.behavior != null" style="flex-wrap: wrap;">
            <div v-for="t in props.data.behavior" class="label me-2 mb-2" :class="getLabel(t)" :key="t">
              {{ t }}
            </div>
          </div>
          <div v-else>No behavior observed</div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-8">
        <div class="box padded dark mb-3" style="padding-left: 1rem; padding-bottom: 1rem">
          <EventGraph :ip="props.data.ip"/>
        </div>
        <div class="box padded">
          <strong class="white d-flex mb-2">
            Network provider
          </strong>
          <div class="kv">
            <div class="item">
              <div>Organization</div>
              <div>{{ props.data.network_provider}}</div>
            </div>
            <div class="item">
              <div>Risk Score</div>
              <div>{{ props.data.network_risk_score}}</div>
            </div>
            <div class="item">
              <div>Network Type</div>
              <div>{{ props.data.network_type}}</div>
            </div>
            <div class="item">
              <div>Network Range</div>
              <div>{{ props.data.network_range}}</div>
            </div>
            <div class="item">
              <div>Business Services</div>
              <div>
                <span class="ms-2 badge text-bg-light" v-for="service in props.data.network_provider_services" :key="service">
                  {{ service }}
                </span>
              </div>
            </div>
            <div class="item">
              <div>Description</div>
              <div style="max-width: 500px; word-break: auto-phrase">{{ props.data.provider_description}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="box padded h100">
          <strong class="white d-flex mb-3">
          open-ports & services
          </strong>

          <div class="kv" v-if="ports != null && ports.length > 0" style="overflow: auto; max-height: 450px;">
            <div v-for="t in ports" class="item port-item" :key="t" data-bs-toggle="tooltip" data-bs-placement="top" :title="t.description">
              <div>{{t.port}}</div>
              <div>{{t.protocol}}</div>
            </div>
          </div>
          <div v-else>No open ports found</div>

        </div>
      </div>
    </div>

    <div class="box mb-4 d-flex column placeholder-glow" :class="{padded: renderDomains}">
      <div class="placeholder" style="height: 500px; width: 100%;" v-if="!renderDomains">
      </div>
      <div class="mb-3"  v-if="renderDomains">
          <strong class="white d-flex">
            Reverse DNS
          </strong>
      </div>

      <div style="flex: 1; overflow: auto;" v-if="renderDomains">
        <div v-if="domains.length > 0" style="overflow:auto; border-radius: 10px; background-color: #181c22; height: 500px;">
          <Table
             :result="domains" 
              style=""
            />
        </div>
        <div v-else>No domains found</div>
      </div>
    </div>

    <div class="box mb-5 d-flex column" :class="{padded: renderHashes}">
      <div class="placeholder" style="height: 500px; width: 100%;" v-if="!renderHashes">
      </div>
      <div class="mb-3" v-if="renderHashes">
          <strong class="white d-flex">
            Fingerprints
          </strong>
      </div>

      <div style="flex: 1; " v-if="renderHashes">
        <div v-if="hashes.length > 0 || !eventAuthorized" style="overflow: auto; border-radius: 10px; background-color: #181c22; height: 500px;">
          <Table
             :result="hashes" 
              style="background-color: #181c22;"
            />
            <div v-if="!eventAuthorized" class="unauthorized">
              <span>To view fingerprints upgrade to the &nbsp;<router-link to="/plans">Elite or Enterprise Plan</router-link></span>
            </div>
        </div>
        <div v-else>No fingerprints found</div>
      </div>
    </div>

  </div>
  </div>
</template>

<style scoped>
h2 {
  font-weight: 500;
}

.h100 {
  height: 100%;
}

.labels {
  display: flex;
}

.label {
  border-radius: 4px; 
  padding: .1rem 1.5rem;
  border: 1px solid gray;
  font-size: .9rem;
}

.unauthorized {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  color: white;
  bottom: 0;
  background-color: #1a1f27e6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning {
  border-color: #fcec88;
  color:#fcec88;
}

.bad {
  border-color: #fc8c88;
  color:#fc8c88;
}


.kv {
  .item:not(:last-child) {
    border-bottom: 1px dashed #ffffff33;
  }
  .item {
    display: flex;
    padding: .5rem 0; 
    
    :last-child {
      margin-left: auto;
      color: white;
    }
  }

}

.port-item div:last-child {
  max-width: 60%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
  
</style>
