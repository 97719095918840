import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class Ip {
  async bulk(items) {
    let { data } = await axios.post(API_URL + '/query/ip/bulk', { ip: items });
    return data;
  }

  async Get(ip) {
    let { data } = await axios.get(API_URL + '/query/ip/'+ip);
    return data;
  }


  async Hashes(ip) {
    let { data } = await axios.get(API_URL + '/query/hash/ip/'+ip);
    return data;
  }

  async EventRange(ip, start, end) {
    let { data } = await axios.post(API_URL + '/query/event/range', {
      ip: ip, 
      start: start,
      end: end
    });

    data = data.data;

    data = data.map( (elem) => {
      let tmp = { timestamp: elem.timestamp }
      Object.assign(tmp, elem.data)
      return tmp
    })

    return data;
  }

  async Events(ip, start, end) {
    let { data } = await axios.post(API_URL + '/query/event/highlights', {
      ip: ip, 
      start: start,
      end: end
    });
    return data;
  }
}

export default new Ip();
