<script setup>
  import { ref } from 'vue'
  import Plans from '@/data/plans.js'
  
  let plans = Plans
</script>

<template>
  <div class="tb-container">
    <table class="mb-5">
      <thead>
        <th class="table-title"></th>
        <th v-for="plan in plans" class="uppercase" :style="{color: plan.color}">{{ plan.title }}</th>
      </thead>
      <tbody>
        <tr>
          <td>Real-time API</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['rts'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['rts']}}</div>
          </td>
        </tr>
        <tr>
          <td>Query</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['query'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['query']}}</div>
          </td>
        </tr>
        <tr>
          <td>API Requests per Second</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['rps'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['rps']}}</div>
          </td>
        </tr>
        <tr>
          <td>API Requests per Day</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['rpd'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['rpd']}}</div>
          </td>
        </tr>
        <tr>
          <td>API Requests per Month</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['rpm'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['rpm']}}</div>
          </td>
        </tr>
        <tr>
          <td>Seats</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['seats'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['seats']}}</div>
          </td>
        </tr>
        <tr>
          <td>Support</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['support'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['support']}}</div>
          </td>
        </tr>
        <tr>
          <td>SLA</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['sla'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else-if="plan.features['sla'] != undefined">{{plan.features['sla']}}</div>
            <!--<font-awesome-icon v-else style="font-size: 1rem;color: #3a3f48" :icon="['far', 'circle-xmark']" />-->
          </td>
        </tr>
      </tbody>
    </table>

    <table class="mb-5">
      <thead class="hidden">
        <th class="table-title">API Data & Endpoints</th>
        <th v-for="plan in plans" class="uppercase hide" :style="{color: plan.color}">{{ plan.title }}</th>
      </thead>
      <tbody>
        <tr>
          <td>IP Information</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['ip_info'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['ip_info']}}</div>
          </td>
        </tr>
        <tr>
          <td>Domain Information</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['domain_info'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['domain_info']}}</div>
          </td>
        </tr>
        <tr>
          <td>Subdomains</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['subdomains'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['subdomains']}}</div>
          </td>
        </tr>
        <tr>
          <td>Similar Domains</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['similar_domains'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['similar_domains']}}</div>
          </td>
        </tr>
        <tr>
          <td>Reverse DNS</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['rdns'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['rdns']}}</div>
          </td>
        </tr>
        <tr>
          <td>Emails</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['emails'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['emails']}}</div>
          </td>
        </tr>
        <tr>
          <td>Hashes & Fingerprint</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['fingerprint'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['fingerprint']}}</div>
          </td>
        </tr>
        <tr>
          <td>Netflow & Events</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['netflow'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['netflow']}}</div>
          </td>
        </tr>
      </tbody>
    </table>

<!--
    <table class="mb-5">
      <thead class="hidden">
        <th class="table-title">Datasets</th>
        <th v-for="plan in plans" class="uppercase hide" :style="{color: plan.color}">{{ plan.title }}</th>
      </thead>
      <tbody>
        <tr>
          <td>IP Blocklist</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['ip_block'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['ip_block']}}</div>
          </td>
        </tr>
        <tr>
          <td>Domain Blocklist</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['domain_block'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['domain_block']}}</div>
          </td>
        </tr>
        <tr>
          <td>Country</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['country'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['country']}}</div>
          </td>
        </tr>
        <tr>
          <td>City & ASN</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['city'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['city']}}</div>
          </td>
        </tr>
        <tr>
          <td>Organizations & Riskscore</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['org'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['org']}}</div>
          </td>
        </tr>
        <tr>
          <td>Anonymization</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['anon'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['anon']}}</div>
          </td>
        </tr>
        <tr>
          <td>Threats</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['threats'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['threats']}}</div>
          </td>
        </tr>
        <tr>
          <td>Forward DNS</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['fdns'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['fdns']}}</div>
          </td>
        </tr>
        <tr>
          <td>New Domains</td>
          <td v-for="plan in plans" class="text-center">
            <font-awesome-icon v-if="plan.features['new_domains'] === true" :style="{color: plan.color}" style="font-size: 1rem;" :icon="['fas', 'check']" />
            <div v-else>{{plan.features['new_domains']}}</div>
          </td>
        </tr>
      </tbody>
    </table>
-->

  </div>
</template>

<style scoped>
table {
  width: 100%;
  table-layout: fixed;


  .hide {
    display: none;
  }
  
  .table-title {
    text-align: left;
    padding: 1.5rem 0;  
    color: white;
    font-size: 1.2rem;
  } 


  th {
    padding: 2rem;
    text-align: center;
  }

  td {
    padding: 1rem;
  }

  th:first-child {
    width: 20rem;
  }

  td:first-child {
    color: white;
  }

  td:not(:first-child) {
    border-left: 1px solid #272F3B;
  }

  th:not(:first-child) {
    border-left: 1px solid #272F3B;
  }

  tr:nth-child(even) {
    background-color: transparent;
  }

  tr:nth-child(odd) {
    background-color: #1D242D;
  }
}

@media (max-width: 600px) {
  .tb-container {
    overflow: auto;
  }

  .table-title {
    width: 10rem !important;
  }

  table {
    min-width: 1080px;
  } 
}
</style>
