module.exports = {
  Ports: [
    {
      "port": 21,
      "protocol": "File Transfer Protocol (FTP)",
      "description": "File Transfer Protocol (FTP) is a standard network protocol used for transferring files from one host to another over a TCP-based network, such as the internet."
    },
    {
      "port": 22,
      "protocol": "Secure Shell (SSH)",
      "description": "Secure Shell (SSH) is a network protocol for securely connecting to a remote computer. It provides secure remote command-line access and remote command execution."
    },
    {
      "port": 23,
      "protocol": "Telnet",
      "description": "Telnet is a network protocol used to establish a connection to a remote computer and access its command-line interface."
    },
    {
      "port": 25,
      "protocol": "Simple Mail Transfer Protocol (SMTP)",
      "description": "Simple Mail Transfer Protocol (SMTP) is a standard protocol for sending email messages between servers. Most email systems that send mail over the internet use SMTP to send messages from one server to another, and to deliver messages to local mail clients like Microsoft Outlook or Apple Mail."
    },
    {
      "port": 80,
      "protocol": "Hypertext Transfer Protocol (HTTP)",
      "description": "Hypertext Transfer Protocol (HTTP) is a protocol for transmitting hypermedia documents, such as HTML pages. It is the foundation of data communication for the World Wide Web."
    },
    {
      "port": 110,
      "protocol": "Post Office Protocol version 3 (POP3)",
      "description": "Post Office Protocol version 3 (POP3) is a protocol used by email clients to retrieve email from a mail server."
    },
    {
      "port": 139,
      "protocol": "Network Basic Input/Output System (NetBIOS) Session Service",
      "description": "Network Basic Input/Output System (NetBIOS) Session Service is a network service that allows applications to communicate with each other over a LAN or WAN."
    },
    {
      "port": 443,
      "protocol": "HTTP Secure (HTTPS)",
      "description": "HTTP Secure (HTTPS) is a secure version of HTTP, the protocol used to transfer data on the web. It adds an extra layer of security by encrypting the data being transmitted."
    },
    {
      "port": 445,
      "protocol": "Microsoft-DS Active Directory",
      "description": "Microsoft-DS Active Directory is a directory service that enables administrators to manage and secure data, users, and devices in a Windows environment."
    },
    {
      "port": 3389,
      "protocol": "Remote Desktop Protocol (RDP)",
      "description": "Remote Desktop Protocol (RDP) is a proprietary protocol developed by Microsoft that allows a user to remotely connect to another computer and control it as if they were sitting in front of it."
    },
    {
      "port": 53,
      "protocol": "Domain Name System (DNS)",
      "description": "Domain Name System (DNS) is a hierarchical decentralized naming system for computers, services, or other resources connected to the internet or a private network. It translates human-readable domain names into numerical IP addresses."
    },
    {
      "port": 111,
      "protocol": "Sun Remote Procedure Call (RPC)",
      "description": "Sun Remote Procedure Call (RPC) is a protocol used for requesting a service from a program located on another computer on a network."
    },
    {
      "port": 135,
      "protocol": "Microsoft Endpoint Mapper",
      "description": "Microsoft Endpoint Mapper is a service that provides a lookup service for Remote Procedure Call (RPC) programs."
    },
    {
      "port": 143,
      "protocol": "Internet Message Access Protocol (IMAP)",
      "description": "Internet Message Access Protocol (IMAP) is a protocol for retrieving email messages from a mail server."
    },
    {
      "port": 993,
      "protocol": "IMAP over SSL (IMAPS)",
      "description": "IMAP over SSL (IMAPS) is a secure version of the IMAP protocol that encrypts the data being transmitted."
    },
    {
      "port": 995,
      "protocol": "Post Office Protocol version 3 over SSL (POP3S)",
      "description": "Post Office Protocol version 3 over SSL (POP3S) is a secure version of the POP3 protocol that encrypts the data being transmitted."
    },
    {
      "port": 1723,
      "protocol": "Point-to-Point Tunneling Protocol (PPTP)",
      "description": "Point-to-Point Tunneling Protocol (PPTP) is a protocol used to support virtual private networks (VPNs)."
    },
    {
      "port": 3306,
      "protocol": "MySQL database server",
      "description": "MySQL database server is a database management system used to store and retrieve data."
    },
    {
      "port": 5900,
      "protocol": "Virtual Network Computing (VNC) remote desktop protocol.",
      "description": "Virtual Network Computing (VNC) remote desktop protocol is a platform-independent protocol that enables users to remotely access and control another computer."
    },
    {
      "port": 8080,
      "protocol": "http-proxy or Kubernetes",
      "description": "A HTTP proxy is a server that acts as an intermediary for requests from clients seeking resources from other servers. It can be used to cache content, filter requests, or apply security measures. Kubernetes is an open-source system for automating the deployment, scaling, and management of containerized applications."
    },
    {
      "port": 81,
      "protocol": "Torpark onion routing",
      "description": "Torpark onion routing is a network routing technique used to anonymize internet traffic by routing it through a series of nodes or \"onion layers\" before reaching its destination."
    },
    {
      "port": 113,
      "protocol": "Ident",
      "description": "Ident (auth) is a protocol used to identify the user of a particular TCP connection. It is often used by servers to identify the user behind a connection when no other authentication mechanism is available."
    },
    {
      "port": 199,
      "protocol": "Simple Network Management Protocol (SNMP) Trap",
      "description": "Cisco systems is a multinational technology company that designs and sells networking equipment, software, and services."
    },
    {
      "port": 465,
      "protocol": "Secure SMTP (Simple Mail Transfer Protocol) over SSL/TLS",
      "description": "Simple Mail Transfer Protocol over SSL (SMTPS) is a secure version of Simple Mail Transfer Protocol (SMTP), which is the standard protocol for sending email. SMTPS encrypts the connection between the sender's and receiver's mail servers to protect the privacy of the email content."
    },
    {
      "port": 548,
      "protocol": "Apple Filing Protocol (AFP)",
      "description": "Apple Filing Protocol (AFP) is a network protocol used by Apple devices to share files over a network."
    },
    {
      "port": 587,
      "protocol": "SMTP (Simple Mail Transfer Protocol)",
      "description": "Email message submission is the process of sending an email message to a mail server for delivery to a recipient's mailbox."
    },
    {
      "port": 1025,
      "protocol": "Unknown",
      "description": "Network Data Management Protocol (NDMP) is a protocol used to facilitate the backup and restoration of data stored on network-attached storage devices."
    },
    {
      "port": 1720,
      "protocol": "H.323",
      "description": "H.323 is a set of standards that define the transmission of real-time audio, video, and data communications over IP networks. The H.323 call setup protocol is used to establish and terminate calls between H.323-enabled devices."
    },
    {
      "port": 6001,
      "protocol": "Unknown",
      "description": "The X Window System is a graphical windowing system for displaying and interacting with applications on a computer. It is used on many Unix and Linux systems."
    },
    {
      "port": 8888,
      "protocol": "HTTP Alternate (HTTP Alt)",
      "description": "HyperText Transfer Protocol Secure (HTTPS) is a secure version of HTTP, the protocol used to transfer data on the web. HTTPS alternate refers to any other secure protocol that can be used in place of HTTPS."
    },
    {
      "port": 179,
      "protocol": "Border Gateway Protocol (BGP)",
      "description": "Border Gateway Protocol (BGP) is a routing protocol used to exchange routing and reachability information between Internet Service Providers (ISPs). It is used to determine the most efficient path for data to travel between networks."
    },
    {
      "port": 514,
      "protocol": "syslog",
      "description": "syslog is a standard for logging system messages on Unix and Linux systems. It is used to record system events, such as system startup, shutdown, and user authentication."
    },
    {
      "port": 554,
      "protocol": "Real Time Streaming Protocol (RTSP)",
      "description": "Real Time Streaming Protocol (RTSP) is a network control protocol used to manage the delivery of streaming media, such as audio and video. It allows a client to control the playback of media from a server."
    },
    {
      "port": 1026,
      "protocol": "Unknown",
      "description": "LMI - LMI Stat is a protocol used to monitor and manage Frame Relay networks. It allows devices on the network to exchange status and performance information."
    },
    {
      "port": 2000,
      "protocol": "Unknown",
      "description": "Cisco SCCP (Skinny Client Control Protocol) is a proprietary protocol used by Cisco Systems for VoIP communications. It is used to control the communication between IP phones and Cisco call control servers."
    },
    {
      "port": 5060,
      "protocol": "Session Initiation Protocol (SIP)",
      "description": "Session Initiation Protocol (SIP) is a signaling protocol used to initiate, maintain, modify, and terminate multimedia sessions (e.g., voice, video, and messaging) over IP networks."
    },
    {
      "port": 8000,
      "protocol": "HTTP Alternate (HTTP Alt)",
      "description": "iRDMI (Intelligent Remote Device Management Interface) is a protocol used to remotely manage and monitor devices over a network."
    },
    {
      "port": 8443,
      "protocol": "HTTPS (Hypertext Transfer Protocol Secure)",
      "description": "HTTPS alternate refers to any other secure protocol that can be used in place of HTTPS."
    },
    {
      "port": 10000,
      "protocol": "Network Data Management Protocol (NDMP)",
      "description": "Network Data Management Protocol (NDMP) is a protocol used to facilitate the backup and restoration of data stored on network-attached storage devices."
    },
    {
      "port": 515,
      "protocol": "line printer daemon (lpd)",
      "description": "This port is used by the Line Printer Daemon (LPD) service, which allows a computer to print to a network printer. It is commonly used in Unix and Linux operating systems."
    },
    {
      "port": 646,
      "protocol": "ldp (Label Distribution Protocol)",
      "description": "This port is used by the DHCP failover protocol, which allows two DHCP servers to communicate with each other and share client lease information. It is commonly used in networks to ensure high availability of DHCP services."
    },
    {
      "port": 1433,
      "protocol": "Microsoft SQL Server",
      "description": "This port is used by the Microsoft SQL Server database management system for client-server communication. It is commonly used to connect to a SQL Server instance from a client application such as SQL Server Management Studio."
    },
    {
      "port": 2001,
      "protocol": "dc (Device Control)",
      "description": "This port is not commonly used for any specific network service. It may be used for unassigned or experimental purposes."
    },
    {
      "port": 5666,
      "protocol": "NRPE (Nagios Remote Plugin Executor)",
      "description": "This port is often used by the Nagios network monitoring software for communication between the Nagios server and its clients. It is also used by other network monitoring and management tools."
    },
    {
      "port": 8008,
      "protocol": "HTTP alternate (http-alt)",
      "description": "This port is often used by web servers as an alternative to the standard HTTP port (port 80). It is also used by some application servers for communication with clients."
    },
    {
      "port": 88,
      "protocol": "Kerberos",
      "description": "This port is used by the Kerberos authentication protocol, which is a network authentication system that uses encrypted keys for secure communication. It is commonly used in networks to provide secure access to network resources."
    },
    {
      "port": 79,
      "protocol": "finger",
      "description": "This port is used by the Finger protocol, which is a simple network service that allows users to query information about other users on a network. It is not commonly used today, as it has been replaced by more secure alternatives."
    },
    {
      "port": 106,
      "protocol": "pop3pw",
      "description": "This port is used by the Poppassd password change service, which allows users to change their passwords remotely over a network. It is not commonly used today, as it has been replaced by more secure alternatives."
    },
    {
      "port": 631,
      "protocol": "Internet Printing Protocol (ipp)",
      "description": "This port is used by the Common Unix Printing System (CUPS), which is a printing service that allows users to print to network printers. It is commonly used in Unix and Linux operating systems."
    },
    {
      "port": 2049,
      "protocol": "Network File System (NFS)",
      "description": "This port is used by the Network File System (NFS), which is a network protocol that allows a computer to access files over a network as if they were on its local hard drive. It is commonly used in Unix and Linux operating systems."
    },
    {
      "port": 5000,
      "protocol": "UPnP",
      "description": "This port is often used by various applications for communication, such as UPnP (Universal Plug and Play) devices and some streaming media servers. It may also be used for unassigned or experimental purposes."
    },
    {
      "port": 5631,
      "protocol": "pcanywhere-data",
      "description": "This port is used by the pcAnywhere remote access software for communication between a host and client. It is not commonly used today, as it has been replaced by more secure alternatives."
    },
    {
      "port": 5800,
      "protocol": "VNC remote desktop",
      "description": "This port is often used by the VNC (Virtual Network Computing) remote desktop protocol for communication between a VNC server and client. It is also used by some other remote access and remote control tools."
    },
    {
      "port": 8081,
      "protocol": "HTTP alternate (http-alt)",
      "description": "This port is often used by web servers as an alternative to the standard HTTP port (port 80). It is also used by some application servers for communication with clients."
    },
    {
      "port": 427,
      "protocol": "Service Location Protocol (SLP)",
      "description": "This port is used by the Service Location Protocol (SLP), which is a network protocol that allows devices to discover and communicate with each other on a network. It is commonly used in networks to facilitate communication between devices and services."
    },
    {
      "port": 513,
      "protocol": "rlogin",
      "description": "This port is used by the Remote Login (rlogin) service, which allows users to log in to a remote computer over a network. It is not commonly used today, as it has been replaced by more secure alternatives."
    },
    {
      "port": 543,
      "protocol": "klogin, Kerberos login",
      "description": "Kerberos is a network authentication protocol that is used to securely authenticate users and devices to network services. The klogin service allows users to log in to a network using the Kerberos protocol. It is typically used in conjunction with the kshell (Kerberos shell) service, which allows users to access a command shell on a network server after they have authenticated using the klogin service."
    },
    {
      "port": 990,
      "protocol": "FTP over TLS/SSL (ftps)",
      "description": "This port is used by the FTP (File Transfer Protocol) Secure (FTPS) service, which is a secure version of FTP that uses SSL/TLS (Secure Sockets Layer/Transport Layer Security) to encrypt data transfer. It is commonly used to transfer files over a network in a secure manner."
    },
    {
      "port": 5357,
      "protocol": "WS-Discovery (Windows Network Projector)",
      "description": "This port is used by the Windows Network Discovery (WSD) service, which allows devices to discover and communicate with each other on a network. It is commonly used in networks to facilitate communication between devices and services."
    },
    {
      "port": 6000,
      "protocol": "X11",
      "description": "This port is often used by the X Window System, which is a network protocol that allows a computer to display a graphical user interface (GUI) remotely over a network. It is commonly used in Unix and Linux operating systems."
    },
    {
      "port": 389,
      "protocol": "LDAP (Lightweight Directory Access Protocol)",
      "description": "This port is used by the Lightweight Directory Access Protocol (LDAP), which is a network protocol used to access and manage directory information over a network. It is commonly used in networks to provide centralized authentication and authorization services."
    },
    {
      "port": 444,
      "protocol": "snpp (Simple Network Paging Protocol)",
      "description": "This port is not commonly used for any specific network service. It may be used for unassigned or experimental purposes."
    },
    {
      "port": 544,
      "protocol": "kshell, Kerberos Remote shell",
      "description": "This port is used by the Remote Shell (rsh) service, which allows users to execute commands on a remote computer over a network. It is not commonly used today, as it has been replaced by more secure alternatives."
    },
    {
      "port": 873,
      "protocol": "rsync",
      "description": "This port is used by the rsync (remote sync) utility, which is a tool used to synchronize files between two computers over a network. It is commonly used to backup and replicate data."
    },
    {
      "port": 9100,
      "protocol": "HP JetDirect printer interface",
      "description": ""
    },
    {
      "port": 9999,
      "protocol": "Urchin Web Analytics",
      "description": ""
    },
    {
      "port": 9990,
      "protocol": "WildFly",
      "description": ""
    },
    {
      "port": 9993,
      "protocol": "WildFly",
      "description": ""
    },
    {
      "port": 5556,
      "protocol": "WebLogic",
      "description": ""
    },
    {
      "port": 7001,
      "protocol": "WebLogic or Cassandra",
      "description": ""
    },
    {
      "port": 7002,
      "protocol": "WebLogic",
      "description": ""
    },
    {
      "port": 8001,
      "protocol": "WebLogic",
      "description": ""
    },
    {
      "port": 9043,
      "protocol": "WebSphere",
      "description": ""
    },
    {
      "port": 9060,
      "protocol": "WebSphere",
      "description": ""
    },
    {
      "port": 9080,
      "protocol": "WebSphere",
      "description": ""
    },
    {
      "port": 9443,
      "protocol": "WebSphere",
      "description": ""
    },
    {
      "port": 8300,
      "protocol": "consul",
      "description": ""
    },
    {
      "port": 8301,
      "protocol": "consul",
      "description": ""
    },
    {
      "port": 8302,
      "protocol": "consul",
      "description": ""
    },
    {
      "port": 8400,
      "protocol": "consul",
      "description": ""
    },
    {
      "port": 8500,
      "protocol": "consul",
      "description": ""
    },
    {
      "port": 8600,
      "protocol": "consul",
      "description": ""
    },
    {
      "port": 2379,
      "protocol": "etcd",
      "description": ""
    },
    {
      "port": 2380,
      "protocol": "etcd",
      "description": ""
    },
    {
      "port": 6443,
      "protocol": "kubernetes",
      "description": ""
    },
    {
      "port": 5050,
      "protocol": "mesos",
      "description": ""
    },
    {
      "port": 5051,
      "protocol": "mesos or ida-agent",
      "description": ""
    },
    {
      "port": 2181,
      "protocol": "zookeeper",
      "description": ""
    },
    {
      "port": 2888,
      "protocol": "zookeeper",
      "description": ""
    },
    {
      "port": 3888,
      "protocol": "zookeeper",
      "description": ""
    },
    {
      "port": 27019,
      "protocol": "MongoDB",
      "description": ""
    },
    {
      "port": 28017,
      "protocol": "MongoDB",
      "description": ""
    },
    {
      "port": 7473,
      "protocol": "Neo4J",
      "description": ""
    },
    {
      "port": 7474,
      "protocol": "Neo4J",
      "description": ""
    },
    {
      "port": 6379,
      "protocol": "Redis",
      "description": ""
    },
    {
      "port": 8087,
      "protocol": "Riak",
      "description": ""
    },
    {
      "port": 8098,
      "protocol": "Riak",
      "description": ""
    },
    {
      "port": 28015,
      "protocol": "RethinkDB",
      "description": ""
    },
    {
      "port": 29015,
      "protocol": "RethinkDB",
      "description": ""
    },
    {
      "port": 7574,
      "protocol": "Solr",
      "description": ""
    },
    {
      "port": 8983,
      "protocol": "Solr",
      "description": ""
    },
    {
      "port": 7210,
      "protocol": "MaxDB",
      "description": ""
    },
    {
      "port": 1521,
      "protocol": "Oracle DB",
      "description": ""
    },
    {
      "port": 1830,
      "protocol": "Oracle DB",
      "description": ""
    },
    {
      "port": 5432,
      "protocol": "PostgreSQL",
      "description": ""
    },
    {
      "port": 1434,
      "protocol": "MSSQL",
      "description": ""
    },
    {
      "port": 280,
      "protocol": "http-mgmt",
      "description": ""
    },
    {
      "port": 311,
      "protocol": "asip-webadmin",
      "description": ""
    },
    {
      "port": 407,
      "protocol": "timbuktu",
      "description": ""
    },
    {
      "port": 497,
      "protocol": "retrospect",
      "description": ""
    },
    {
      "port": 464,
      "protocol": "kpasswd5",
      "description": ""
    },
    {
      "port": 992,
      "protocol": "telnets",
      "description": ""
    },
    {
      "port": 999,
      "protocol": "garcon",
      "description": ""
    },
    {
      "port": 1000,
      "protocol": "cadlock",
      "description": ""
    },
    {
      "port": 1022,
      "protocol": "exp2",
      "description": ""
    },
    {
      "port": 1023,
      "protocol": "netvenuechat",
      "description": ""
    },
    {
      "port": 1024,
      "protocol": "kdm",
      "description": ""
    },
    {
      "port": 69,
      "protocol": "Trivial File Transfer Protocol (TFTP)",
      "description": ""
    },
    {
      "port": 161,
      "protocol": "SNMP (Simple Network Management Protocol)",
      "description": ""
    },
    {
      "port": 2052,
      "protocol": "",
      "description": "This port is not a well-known port, so there is no common service associated with it."
    },
    {
      "port": 2083,
      "protocol": "cPanel",
      "description": "This port is commonly used for web hosting control panel systems, such as cPanel."
    },
    {
      "port": 2086,
      "protocol": "WebHost Manager (WHM)",
      "description": "This port is commonly used for web hosting control panel systems, such as WebHost Manager (WHM)."
    },
    {
      "port": 2096,
      "protocol": "cPanel",
      "description": "This port is commonly used for web hosting control panel systems, such as cPanel."
    },
    {
      "port": 8880,
      "protocol": "VPN services",
      "description": "This port is commonly used for web servers and web-based applications. It is also sometimes used for VPN connections."
    },
    {
      "port": 2087,
      "protocol": "WebHost Manager (WHM)",
      "description": "This port is commonly used for web hosting control panel systems, such as WebHost Manager (WHM)."
    },
    {
      "port": 20000,
      "protocol": "DCE/RPC Locator",
      "description": "DCE/RPC (Distributed Computing Environment/Remote Procedure Call) is a network protocol that allows a program on one computer to call a subroutine on another computer. The DCE/RPC Locator service is responsible for locating and connecting to other DCE/RPC servers on the network."
    },
    {
      "port": 50000,
      "protocol": "Sybase",
      "description": "Sybase is a database management system that is used to store, retrieve, and manipulate data. It is often used in enterprise-level systems."
    },
    {
      "port": 5001,
      "protocol": "SOCKS",
      "description": "SOCKS is a protocol that allows a client to connect to a server through a proxy server. It is often used to bypass firewalls or to access servers that are blocked in certain countries."
    },
    {
      "port": 5002,
      "protocol": "SOCKS",
      "description": "SOCKS is a protocol that allows a client to connect to a server through a proxy server. It is often used to bypass firewalls or to access servers that are blocked in certain countries."
    },
    {
      "port": 6667,
      "protocol": "IRC (Internet Relay Chat)",
      "description": "IRC is a protocol that allows users to communicate with each other in real-time over the internet. It is often used for group discussions or to chat with friends."
    },
    {
      "port": 8181,
      "protocol": "HTTP alternate",
      "description": "HTTP (Hypertext Transfer Protocol) is a protocol that is used to transfer data over the internet. HTTP alternate is a variant of HTTP that may be used for specific purposes, such as to bypass firewalls or for compatibility with certain systems."
    },
    {
      "port": 9418,
      "protocol": "Git",
      "description": "Git is a version control system that is used to track changes to files and coordinate work among multiple users. It is often used in software development projects."
    },
    {
      "port": 11211,
      "protocol": "Memcached",
      "description": "Memcached is a distributed memory caching system that is used to speed up dynamic web applications by storing data in memory. It is often used to reduce the load on databases or other data stores."
    },
    {
      "port": 162,
      "protocol": "SNMP (Simple Network Management Protocol)",
      "description": "SNMP is a protocol that is used to manage and monitor network devices, such as routers, switches, and servers. It allows administrators to retrieve information about the status and performance of the devices and to configure them remotely."
    },
    {
      "port": 9001,
      "protocol": "TOR originating port",
      "description": "TOR (The Onion Router) is a network that allows users to browse the internet anonymously. The TOR originating port is the port on which the TOR client listens for connections from other TOR nodes."
    },
    {
      "port": 9050,
      "protocol": "TOR SOCKS port",
      "description": "SOCKS is a protocol that allows a client to connect to a server through a proxy server. The TOR SOCKS port is the port on which the TOR client listens for connections from SOCKS clients."
    },
    {
      "port": 1527,
      "protocol": "Apache Derby",
      "description": "Apache Derby is an open-source database management system that is written in Java. It is often used for small- to medium-sized applications that need a lightweight, embedded database."
    },
    {
      "port": 60000,
      "protocol": "Apache HBase",
      "description": "Apache HBase is a distributed, column-oriented database that is built on top of the Apache Hadoop framework. It is often used for large-scale, real-time data processing applications."
    },
    {
      "port": 8091,
      "protocol": "Couchbase",
      "description": "Couchbase is a NoSQL database management system that is designed to be scalable, flexible, and high-performing. It is often used for applications that need to store and process large amounts of data in real-time."
    },
    {
      "port": 446,
      "protocol": "Microsoft Access",
      "description": "Microsoft Access is a database management system that is part of the Microsoft Office suite of productivity tools. It is often used for small- to medium-sized applications that need a simple, easy-to-use database."
    },
    {
      "port": 11000,
      "protocol": "Oracle Berkeley DB",
      "description": "Oracle Berkeley DB is a high-performance, embedded database management system that is written in C. It is often used for applications that need a fast, reliable, and lightweight database."
    },
    {
      "port": 2424,
      "protocol": "OrientDB",
      "description": "OrientDB is an open-source, multi-model database management system that is written in Java. It is often used for applications that need to store and process large amounts of data in real-time."
    },
    {
      "port": 1337,
      "protocol": "Parse Server",
      "description": "Parse Server is an open-source backend-as-a-service platform that allows developers to build and host mobile and web applications. It provides a set of APIs and tools for storing, retrieving, and manipulating data, as well as for handling user authentication and other common backend tasks."
    },
    {
      "port": 39013,
      "protocol": "Percona Server for MySQL",
      "description": "Percona Server for MySQL is a fork of the MySQL database management system that is optimized for performance and scalability. It is often used for high-traffic web applications and other demanding workloads."
    },
    {
      "port": 21212,
      "protocol": "VoltDB",
      "description": "VoltDB is a in-memory, distributed database management system that is designed for real-time, high-concurrency workloads. It is often used for applications that need to process large amounts of data in real-time, such as Internet of Things (IoT) and financial applications."
    },
    {
      "port": 8765,
      "protocol": "Apache Phoenix",
      "description": "Apache Phoenix is a SQL layer on top of Apache HBase that allows users to query and modify HBase data using SQL syntax. It is often used for applications that need to store and process large amounts of data in real-time."
    },
    {
      "port": 21050,
      "protocol": "Cloudera Impala",
      "description": "Cloudera Impala is a distributed, in-memory SQL query engine that is designed for real-time, high-concurrency workloads. It is often used for applications that need to process large amounts of data in real-time, such as business intelligence and analytics applications."
    },
    {
      "port": 5439,
      "protocol": "Amazon Redshift",
      "description": "Amazon Redshift is a cloud-based data warehousing service that is designed for fast querying and analysis of data. It is often used for business intelligence and analytics applications."
    },
    {
      "port": 10255,
      "protocol": "Azure Cosmos DB",
      "description": "Azure Cosmos DB is a globally distributed, multi-model database service that is provided by Microsoft Azure. It is designed for high performance and scalability, and supports a variety of data models and query languages."
    },
    {
      "port": 26257,
      "protocol": "CockroachDB",
      "description": "CockroachDB is a distributed, scalable database management system that is designed for mission-critical applications. It is based on the design principles of Google's Spanner database."
    },
    {
      "port": 4200,
      "protocol": "CrateDB",
      "description": "CrateDB is an open-source, distributed SQL database management system that is designed for real-time analytics and machine learning applications. It is based on the design principles of Google's BigTable database."
    },
    {
      "port": 5228,
      "protocol": "Firebase Realtime Database",
      "description": "Firebase Realtime Database is a cloud-based NoSQL database service that is provided by Google. It is designed for real-time data synchronization and offline access, and is often used for mobile and web applications."
    },
    {
      "port": 10002,
      "protocol": "Microsoft Azure Table Storage",
      "description": "Microsoft Azure Table Storage is a NoSQL storage service that is provided by Microsoft Azure. It is designed for storing large amounts of structured data, and is often used for applications that need to scale horizontally."
    },
    {
      "port": 48004,
      "protocol": "NuoDB",
      "description": "NuoDB is a distributed, scalable database management system that is designed for cloud environments. It is often used for applications that need to support high levels of concurrency and that require fast read and write performance."
    },
    {
      "port": 9997,
      "protocol": "Apache Accumulo",
      "description": "Apache Accumulo is a distributed, scalable, and secure NoSQL database management system that is based on Google's BigTable design. It is often used for applications that need to store and process large amounts of data in real-time, such as in the intelligence and defense sectors."
    },
    {
      "port": 6123,
      "protocol": "Apache Flink",
      "description": "Apache Flink is an open-source platform for distributed stream and batch data processing. It is often used for applications that need to process large amounts of data in real-time, such as real-time analytics, fraud detection, and IoT applications."
    },
    {
      "port": 10334,
      "protocol": "Apache Geode",
      "description": "Apache Geode: Apache Geode is an in-memory data grid platform that is used to build highly scalable and transactional applications. It is often used for applications that need real-time data processing, such as e-commerce, finance, and social media."
    },
    {
      "port": 7050,
      "protocol": "Apache Kudu",
      "description": "Apache Kudu: Apache Kudu is a distributed, column-oriented data store that is designed for fast analytics on fast data. It is often used for applications that need real-time data processing, such as fraud detection and internet of things (IoT) applications."
    },
    {
      "port": 8100,
      "protocol": "Apache OpenOffice Base",
      "description": "Apache OpenOffice Base: Apache OpenOffice Base is a database management system that is part of the Apache OpenOffice suite of productivity tools. It is often used for small- to medium-sized applications that need a simple, easy-to-use database."
    },
    {
      "port": 4040,
      "protocol": "Apache Spark",
      "description": "Apache Spark: Apache Spark is a distributed data processing platform that is designed for fast and flexible data analytics. It is often used for applications that need real-time data processing, such as machine learning and stream processing."
    },
    {
      "port": 7187,
      "protocol": "Cloudera Navigator",
      "description": "Cloudera Navigator: Cloudera Navigator is a tool that is used to manage and monitor Cloudera's distribution of Apache Hadoop (CDH). It provides features such as data lineage, data discovery, and data governance."
    },
    {
      "port": 4984,
      "protocol": "Couchbase Sync Gateway",
      "description": "Couchbase Sync Gateway: Couchbase Sync Gateway is a tool that is used to synchronize data between a Couchbase Server and mobile or web applications. It is often used for applications that need to store and process large amounts of data in real-time."
    },
    {
      "port": 9600,
      "protocol": "Elasticsearch X-Pack",
      "description": "Elasticsearch X-Pack: Elasticsearch X-Pack is a set of features that extend the capabilities of the Elasticsearch search and analytics engine. It includes features such as security, monitoring, alerting, and machine learning."
    },
    {
      "port": 8086,
      "protocol": "InfluxDB Enterprise",
      "description": "InfluxDB Enterprise: InfluxDB Enterprise is a commercial version of the InfluxDB open-source time series database. It includes features such as high availability, clustering, and advanced monitoring and alerting."
    },
    {
      "port": 41414,
      "protocol": "Apache Flume",
      "description": "Apache Flume: Apache Flume is a distributed, reliable, and available service for efficiently collecting, aggregating, and moving large amounts of log data. It is often used to move log data from multiple sources into a centralized data store, such as Apache Hadoop or Apache Solr."
    },
    {
      "port": 9083,
      "protocol": "Apache Hive Metastore",
      "description": "Apache Hive Metastore: Apache Hive is a data warehousing and SQL-like query language for Apache Hadoop. The Hive Metastore is a service that stores metadata about the Hive tables and partitions in a relational database."
    },
    {
      "port": 8083,
      "protocol": "Apache Kafka Connect",
      "description": "Apache Kafka Connect: Apache Kafka Connect is a tool for scalable and reliable streaming data between Apache Kafka and other systems. It can be used to import data into Kafka or export data from Kafka to other systems."
    },
    {
      "port": 8082,
      "protocol": "Apache Kafka REST Proxy",
      "description": "Apache Kafka REST Proxy: Apache Kafka REST Proxy is a service that allows clients to interact with Kafka using a RESTful API. It can be used to produce and consume messages, or to perform administrative tasks on Kafka clusters."
    },
    {
      "port": 8998,
      "protocol": "Apache Livy",
      "description": "Apache Livy: Apache Livy is a service that allows users to submit Spark jobs and interact with Spark clusters over a RESTful API. It is often used to build applications that need to process data using Spark, without the need for a Spark installation on the client side."
    },
    {
      "port": 18080,
      "protocol": "Apache Nifi Registry",
      "description": "Apache NiFi Registry: Apache NiFi Registry is a centralized repository for versioned NiFi flow artifacts, such as templates and processors. It is used to manage and track the changes to NiFi flows, and to ensure that flows are deployed consistently across environments."
    },
    {
      "port": 6700,
      "protocol": "Apache Storm",
      "description": "Apache Storm: Apache Storm is a distributed, real-time data processing platform that is designed for fast and reliable data processing. It is often used for applications that need to process large amounts of data in real-time, such as real-time analytics, fraud detection, and internet of things (IoT) applications."
    },
    {
      "port": 7180,
      "protocol": "Cloudera Manager",
      "description": "Cloudera Manager: Cloudera Manager is a tool that is used to manage and monitor Cloudera's distribution of Apache Hadoop (CDH). It provides features such as cluster and service management, monitoring, and alerting."
    },
    {
      "port": 7186,
      "protocol": "Cloudera Navigator Metadata Server",
      "description": "Cloudera Navigator Metadata Server: Cloudera Navigator is a tool that is used to manage and monitor Cloudera's distribution of Apache Hadoop (CDH). The Navigator Metadata Server is a service that stores metadata about the data in the CDH cluster, such as data lineage, data discovery, and data governance."
    },
    {
      "port": 4985,
      "protocol": "Couchbase Mobile Sync Gateway",
      "description": "Couchbase Mobile Sync Gateway: Couchbase Mobile Sync Gateway is a tool that is used to synchronize data between a Couchbase Server and mobile or web applications. It is often used for applications that need to store and process large amounts of data in real-time."
    },
    {
      "port": 9000,
      "protocol": "Hadoop NameNode",
      "description": "Hadoop NameNode: Hadoop is a distributed computing framework for processing large amounts of data. The NameNode is a service that manages the file system namespace and metadata for a Hadoop cluster. It is the central point of control for the entire Hadoop cluster."
    },
    {
      "port": 8032,
      "protocol": "Hadoop ResourceManager",
      "description": "Hadoop ResourceManager: Hadoop is a distributed computing framework for processing large amounts of data. The ResourceManager is a service that is responsible for allocating resources to applications running on a Hadoop cluster. It is the central point of control for scheduling jobs on the cluster."
    },
    {
      "port": 8529,
      "protocol": "ArangoDB",
      "description": "ArangoDB: ArangoDB is a distributed, multi-model database management system that is written in C++. It is often used for applications that need to store and process large amounts of data in real-time, such as social networks, fraud detection, and recommendation engines."
    },
    {
      "port": 7000,
      "protocol": "Cassandra",
      "description": "Cassandra: Cassandra is a distributed, NoSQL database management system that is designed for high availability and scalability. It is often used for applications that need to store and process large amounts of data in real-time, such as internet of things (IoT) and online gaming applications."
    },
    {
      "port": 9042,
      "protocol": "Cassandra",
      "description": "Cassandra: Cassandra is a distributed, NoSQL database management system that is designed for high availability and scalability. It is often used for applications that need to store and process large amounts of data in real-time, such as internet of things (IoT) and online gaming applications."
    },
    {
      "port": 5984,
      "protocol": "CouchDB",
      "description": "CouchDB: CouchDB is a distributed, NoSQL database management system that is designed for storing and retrieving large amounts of data quickly and efficiently. It is often used for applications that need to store and process large amounts of data in real-time, such as social networks, content management systems, and analytics platforms."
    },
    {
      "port": 9200,
      "protocol": "Elasticsearch",
      "description": "Elasticsearch: Elasticsearch is a distributed, real-time search and analytics engine that is designed for high performance and scalability. It is often used for applications that need fast search and analysis of large amounts of data, such as log analysis, business intelligence, and e-commerce."
    },
    {
      "port": 9300,
      "protocol": "Elasticsearch",
      "description": "Elasticsearch: Elasticsearch is a distributed, real-time search and analytics engine that is designed for high performance and scalability. It is often used for applications that need fast search and analysis of large amounts of data, such as log analysis, business intelligence, and e-commerce."
    },
    {
      "port": 27017,
      "protocol": "MongoDB",
      "description": "MongoDB: MongoDB is a distributed, NoSQL database management system that is designed for storing and processing large amounts of data quickly and efficiently. It is often used for applications that need to store and process large amounts of data in real-time, such as social networks, content management systems, and analytics platforms."
    },
    {
      "port": 27018,
      "protocol": "MongoDB",
      "description": "MongoDB: MongoDB is a distributed, NoSQL database management system that is designed for storing and processing large amounts of data quickly and efficiently. It is often used for applications that need to store and process large amounts of data in real-time, such as social networks, content management systems, and analytics platforms."
    }
  ]
}
